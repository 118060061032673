
import { defineComponent, PropType } from "vue";
import MdAvatarMixin from "./MdAvatarMixin";

export default defineComponent({
  name: "MdAvatar",
  mixins: [MdAvatarMixin],
  props: {
    variant: {
      type: String as PropType<"monogram" | "image" | "icon">,
      default: "monogram",
    },
    content: {
      type: String,
      default: "A",
    },
    raw: String,
  },
  data() {
    return {};
  },
  computed: {
    initials(): string {
      if (this.raw && this.raw !== "") {
        const splitted = this.raw.split(" ");
        if (splitted.length === 2) {
          return splitted[0][0] + splitted[1][0];
        } else if (splitted.length === 1) {
          return splitted[0][0];
        }
      }
      return this.content.toUpperCase();
    },
  },
});
