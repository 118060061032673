
import { defineComponent, inject, PropType } from "vue";
import { RowBadgeTypes } from "../MdTable";
import MdButton from "@/components/md/MdButton/MdButton.vue";

export default defineComponent({
  name: "MdBadge",
  components: { MdButton },
  emits: ["close"],
  props: {
    state: {
      type: String as PropType<RowBadgeTypes>,
      default: "neutral",
    },
    icon: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      required: false,
    },
    size: {
      type: String as PropType<"medium" | "small">,
      default: "medium",
    },
    closable: {
      type: Boolean,
      default: false,
    },
    color: String,
  },
  data() {
    return {
      iconsByState: {
        success: "check_circle",
        error: "error",
        warning: "warning",
        neutral: "info",
        info: "info",
      },
    };
  },
});
