import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42c79fb8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "material-icons-round"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_md_button = _resolveComponent("md-button")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["md-badge", [
      _ctx.state,
      _ctx.icon ? 'icon' : '',
      _ctx.size,
      _ctx.closable ? 'closable' : '',
      _ctx.color ? 'custom' : '',
    ]]),
    style: _normalizeStyle({ backgroundColor: _ctx.color })
  }, [
    (_ctx.icon)
      ? (_openBlock(), _createElementBlock("i", _hoisted_1, _toDisplayString(_ctx.iconName ? _ctx.iconName : _ctx.iconsByState[_ctx.state]), 1))
      : _createCommentVNode("", true),
    _createElementVNode("span", null, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    (_ctx.closable)
      ? (_openBlock(), _createBlock(_component_md_button, {
          key: 1,
          variant: "icon",
          mode: "icon_small",
          grey: "",
          icon: "close",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }))
      : _createCommentVNode("", true)
  ], 6))
}